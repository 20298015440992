import * as React from 'react'

import { HorizontalAlignment } from '@thg-commerce/enterprise-core'
import { Button, ButtonEmphases } from '@thg-commerce/gravity-elements'

import { LogoPicture, LogoProps } from './LogoImage'
import {
  Container,
  LogoItem,
  LogoWrapper,
  StyledGrid,
  StyledGridItem,
  SubTitle,
  Title,
} from './styles'

export interface LogoImageCollectionProps {
  title?: string
  subtitle?: string
  logos: LogoProps[]
  horizontalAlignment?: HorizontalAlignment
  itemHorizontalAlignment?: HorizontalAlignment
  cta?: CtaProps
}

interface CtaProps {
  text: string
  link: string
  emphasis?: ButtonEmphases | 'high'
}

export const LogoImageCollection = ({
  horizontalAlignment = 'center',
  ...props
}: LogoImageCollectionProps) => {
  const oddTotalItems = props.logos.length % 2 !== 0
  const logos = props.logos.map((logo: LogoProps, index: number) => {
    return (
      <LogoItem key={index} oddTotalItems={oddTotalItems}>
        <LogoPicture
          key={index}
          {...logo}
          horizontalAlignment={
            props.itemHorizontalAlignment || horizontalAlignment
          }
        />
      </LogoItem>
    )
  })

  const colTitleStart = [
    { left: 1, center: 3, right: 5 },
    { left: 1, center: 4, right: 7 },
  ]

  return (
    <Container horizontalAlignment={horizontalAlignment}>
      <StyledGrid columns={12}>
        <StyledGridItem
          colSpan={[12, 8, 6, 6]}
          colStart={
            horizontalAlignment
              ? [
                  1,
                  colTitleStart[0][horizontalAlignment],
                  colTitleStart[1][horizontalAlignment],
                  colTitleStart[1][horizontalAlignment],
                ]
              : 1
          }
        >
          {props.title && <Title>{props.title}</Title>}
          {props.subtitle && <SubTitle>{props.subtitle}</SubTitle>}
        </StyledGridItem>
      </StyledGrid>
      <LogoWrapper horizontalAlignment={horizontalAlignment}>
        {logos}
      </LogoWrapper>
      {props?.cta && (
        <Button href={props.cta.link} emphasis={props.cta?.emphasis}>
          {props.cta.text}
        </Button>
      )}
    </Container>
  )
}
