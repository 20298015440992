import { HorizontalAlignment } from '@thg-commerce/enterprise-core'
import { mq, spacing, styled, Text } from '@thg-commerce/enterprise-theme'
import { Grid, GridItem, Picture } from '@thg-commerce/gravity-system'

export const Title = styled.h2`
  ${(props) =>
    Text(
      props.theme.widget.logoImageCollection.title.font.entry,
      'alternate',
      props.theme.widget.logoImageCollection.title.font.hasScaledText,
    )};
  margin-bottom: ${spacing(1)};
  text-align: inherit;
`

export const SubTitle = styled.p`
  ${Text('bodyText', 'default')};
  margin-bottom: ${spacing(3)};
  text-align: inherit;
`

export const Container = styled.div<{
  horizontalAlignment?: HorizontalAlignment
}>`
  text-align: ${(props) => props.horizontalAlignment || 'left'};
  width: 100%;
  max-width: ${(props) => props.theme.site.siteWidth};
  margin: 0 auto;
  padding: ${spacing(1)} ${spacing(2)};

  ${(props) => mq(props.theme.breakpointUtils.map, 'sm')} {
    padding: ${spacing(2)} ${spacing(4)};
  }
`
export const LogoPictureContainer = styled.div`
  padding: 0;
  width: 100%;
  max-width: ${(props) => props.theme.site.siteWidth};
  opacity: 0.8;

  &:hover {
    opacity: 1;
  }
`

export const StyledGrid = styled(Grid)`
  margin: 0;
`

export const StyledGridItem = styled(GridItem)`
  margin: 0;
`

export const LogoWrapper = styled.ul<{
  horizontalAlignment?: HorizontalAlignment
}>`
  display: flex;
  flex-wrap: wrap;
  justify-content: ${(props) => props.horizontalAlignment || 'left'};
  align-items: center;
  margin: 0 -${spacing(1)};

  ${(props) => mq(props.theme.breakpointUtils.map, 'sm')} {
    margin-right: -${spacing(2)};
    margin-left: -${spacing(2)};
  }

  ${(props) => mq(props.theme.breakpointUtils.map, 'md')} {
    gap: ${spacing(1)};
  }
`

export const StyledPicture = styled(Picture)`
  width: 100%;
  max-width: 180px;
  aspect-ratio: auto 1 / 1;

  ${(props) => mq(props.theme.breakpointUtils.map, 'md')} {
    max-width: 130px;
  }

  ${(props) => mq(props.theme.breakpointUtils.map, 'lg')} {
    max-width: 190px;
  }
`

export const LogoItem = styled.li<{ oddTotalItems: boolean }>`
  // 2 items per row on mobile
  flex-basis: calc(50%);
  width: 100%;

  ${(props) => mq(props.theme.breakpointUtils.map, 'sm')} {
    // 3 items per row on tablet
    flex-basis: calc(33%);
  }

  ${(props) => mq(props.theme.breakpointUtils.map, 'md')} {
    // 6 items per row on laptop
    flex-basis: calc(16.6% - ${spacing(1)});
  }
`

export const StyledLink = styled.a`
  outline: none;
  text-decoration: none;

  display: block;
  border: 2px solid transparent;

  &:focus {
    border: 2px solid ${(props) => props.theme.colors.palette.brand.base};
  }
`

export const LogoText = styled.p<{
  horizontalAlignment?: HorizontalAlignment
}>`
  ${Text('medium1', 'alternate')};
  text-align: ${(props) => props.horizontalAlignment};
  margin-top: ${spacing(2)};
`
