import * as React from 'react'

import { useTheme } from '@thg-commerce/enterprise-core'
import { Logo } from '@thg-commerce/enterprise-graphql/aurora-schema'
import {
  GlobalBrandLogos,
  GlobalScalableLogos,
} from '@thg-commerce/enterprise-network/src/generated/graphql'
import {
  LogoImageCarousel,
  LogoImageCarouselProps,
  LogoImageCollection,
  LogoImageCollectionProps,
} from '@thg-commerce/enterprise-widget-logo-image-collection'

import { BaseWidgetProps } from '../types'
import { WidgetTypes } from '../WidgetTypes'

export type HorizontalAlignment = 'left' | 'center' | 'right'

export type LogoImageCollectionRendererProps = BaseWidgetProps &
  (Omit<GlobalBrandLogos, 'query'> | Omit<GlobalScalableLogos, 'query'>) & {
    widgetIndex: number
    logos: Logo[]
  }

export const LogoImageCollectionRenderer = (
  props: LogoImageCollectionRendererProps,
) => {
  const theme = useTheme()
  if (props.__typename === WidgetTypes.GlobalBrandLogos) {
    const logoImageCollectionProps:
      | LogoImageCollectionProps
      | LogoImageCarouselProps = {
      title: props.title || undefined,
      logos: [
        {
          link: props.logos[0]?.link,
          picture: props.logos[0]?.picture,
          widgetIndex: props.widgetIndex,
        },
        {
          link: props.logos[1]?.link,
          picture: props.logos[1]?.picture,
          widgetIndex: props.widgetIndex,
        },
        {
          link: props.logos[2]?.link,
          picture: props.logos[2]?.picture,
          widgetIndex: props.widgetIndex,
        },
        ...(props.itemFourImage
          ? [
              {
                link: props.logos[3]?.link,
                picture: props.logos[3]?.picture,
                widgetIndex: props.widgetIndex,
              },
            ]
          : []),
        ...(props.itemFiveImage
          ? [
              {
                link: props.logos[4]?.link,
                picture: props.logos[4]?.picture,
                widgetIndex: props.widgetIndex,
              },
            ]
          : []),
        ...(props.itemSixImage
          ? [
              {
                link: props.logos[5]?.link,
                picture: props.logos[5]?.picture,
                widgetIndex: props.widgetIndex,
              },
            ]
          : []),
      ],
      horizontalAlignment:
        (props.titleAlign as HorizontalAlignment) || undefined,
    }
    return props.isScrollable ? (
      <LogoImageCarousel
        {...{
          ...logoImageCollectionProps,
          cta: undefined,
          theme: theme.widget.logoImageCollection,
        }}
      />
    ) : (
      <LogoImageCollection {...logoImageCollectionProps} />
    )
  }

  if (props.__typename === WidgetTypes.GlobalScalableLogos) {
    const { logos: widgetLogos } = props

    const logoImageCollectionProps: LogoImageCollectionProps = {
      logos: [
        {
          title: widgetLogos[0].title,
          link: widgetLogos[0].link,
          picture: widgetLogos[0].picture,
          widgetIndex: props.widgetIndex,
        },
        {
          title: widgetLogos[1].title,
          link: widgetLogos[1].link,
          picture: widgetLogos[1].picture,
          widgetIndex: props.widgetIndex,
        },
        ...(props.imagePathThree
          ? [
              {
                title: widgetLogos[2].title,
                link: widgetLogos[2].link,
                picture: widgetLogos[2].picture,
                widgetIndex: props.widgetIndex,
              },
            ]
          : []),
        ...(props.imagePathFour
          ? [
              {
                title: widgetLogos[3].title,
                link: widgetLogos[3].link,
                picture: widgetLogos[3].picture,
                widgetIndex: props.widgetIndex,
              },
            ]
          : []),
        ...(props.imagePathFive
          ? [
              {
                title: widgetLogos[4].title,
                link: widgetLogos[4].link,
                picture: widgetLogos[4].picture,
                widgetIndex: props.widgetIndex,
              },
            ]
          : []),
        ...(props.imagePathSix
          ? [
              {
                title: widgetLogos[5].title,
                link: widgetLogos[5].link,
                picture: widgetLogos[5].picture,
                widgetIndex: props.widgetIndex,
              },
            ]
          : []),
      ],
    }
    return <LogoImageCollection {...logoImageCollectionProps} />
  }

  return null
}
