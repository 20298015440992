import * as React from 'react'

import { HorizontalAlignment } from '@thg-commerce/enterprise-core'
import { PictureProps } from '@thg-commerce/gravity-system'
import { withPrefetch } from '@thg-commerce/gravity-system/prefetch'

import {
  LogoPictureContainer,
  LogoText,
  StyledLink,
  StyledPicture,
} from './styles'

export interface LogoProps {
  widgetIndex: number
  picture: PictureProps
  horizontalAlignment?: HorizontalAlignment
  link?: string
  title?: string
}

const PrefetchStyledLink = withPrefetch('href', StyledLink)

export const LogoPicture = (props: LogoProps) => {
  const picture = (
    <LogoPictureContainer data-testid="logo-picture-container">
      <StyledPicture
        aspectRatio={'1/1 auto'}
        lazy={props.widgetIndex > 1}
        {...props.picture}
      />
      {props?.title && (
        <LogoText horizontalAlignment={props.horizontalAlignment}>
          {props.title}
        </LogoText>
      )}
    </LogoPictureContainer>
  )

  return props?.link ? (
    <PrefetchStyledLink
      aria-label={props?.title || props.picture?.alt || ''}
      href={props.link}
    >
      {picture}
    </PrefetchStyledLink>
  ) : (
    picture
  )
}
